// Primary color (tincture)
$primary-light-translucent: #e551c740;
$primary-light: #e551c7;
$primary-medium: #b4009e;
$primary-dark: #7a007c;

// Shades (for text)
$shade-light: #ffffff;
$shade-medium: #495057;
$shade-dark: #343a40;

// Translucent (for backgrounds)
$translucent: #ffffff40;
