@import 'colors';

@media (max-width: 600px) {
  .version2-waiting {
    padding: 2rem 2rem;
  }

  ul.collection,
  ul.collection-back {
    grid-template-columns: repeat(5, 1fr) !important;

    > li {
      display: flex;
      flex-direction: column;

      h3 {
        font-size: 2.4rem !important;
        flex: 1;
        display: flex;
        align-items: center;
      }
    }

    .date-badge {
      position: initial;
      text-align: center;
      border-radius: 0;
      padding: 0;
      width: 100%;
    }
  }
}

@media (max-width: 400px) {
  ul.collection,
  ul.collection-back {
    grid-template-columns: repeat(4, 1fr) !important;

    > li {
      h3 {
        font-size: 3.2rem !important;
      }
    }
  }
}
