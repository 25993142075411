@media (min-width: 768px) {
  .popup {
    max-width: 40rem;
    max-height: 60rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.4);
  }
}
