@import 'colors';

.container {
  width: 56rem;
}

.version2-waiting {
  max-width: 32rem;
  width: 100%;
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  align-items: center;

  span {
    color: $shade-light;
  }

  .progress {
    width: 100%;
    height: 15px;
    background-color: $translucent;
    border-radius: 99px;
    display: flex;
    align-items: center;
    padding: 4px;

    > div {
      width: 100%;
      height: 100%;
      background-color: $shade-light;
      border-radius: 99px;
    }
  }
}

.container--reward {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  > button.button--show-collection {
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
  }
}

.container--auth {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  align-items: center;
}

.input-with-button {
  border: 2px dashed $primary-medium;
  border-radius: 99px;
  display: flex;
  padding: 0.4rem 0.8rem;
  width: fit-content;

  input {
    width: 100%;
    max-width: 32rem;
    font-family: 'Outfit', sans-serif;
    font-size: 2.4rem;
    padding: 1rem;
    padding-left: 2rem;
    letter-spacing: 0.3px;
    outline: none;
    border: none;
    background: none;
    color: $primary-medium;
    user-select: text;
    -webkit-user-select: text;
  }

  button.icon {
    color: $shade-light;
    background: $primary-medium;
    border-radius: 99px;
    padding: 2.4rem;
  }
}

button.icon {
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  img {
    width: 4rem;
  }
}

button.button {
  width: 100%;
  max-width: 32rem;
  padding: 1rem;
  border-radius: 99px;
  border: none;
  font-family: 'Outfit', sans-serif;
  font-size: 1.6rem;
  letter-spacing: 0.3px;
  cursor: pointer;
  background-color: $primary-medium;
  color: $shade-light;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: $primary-dark;
  }

  .flex-row {
    justify-content: center;
  }
}

.button-reward-box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;

  img {
    width: 10rem;
    aspect-ratio: 1;
  }

  button {
    width: 100%;
    max-width: 32rem;
    padding: 1rem;
    border-radius: 99px;
    border: none;
    font-family: 'Outfit', sans-serif;
    font-size: 1.6rem;
    letter-spacing: 0.3px;
    cursor: pointer;
    background-color: $primary-medium;
    color: $shade-light;
    transition: all 0.2s ease-in-out;

    &:not(.active) {
      background-color: #00000060;
      color: $shade-light;
      cursor: default;
    }

    &.active:hover {
      background-color: $primary-dark;
    }

    .flex-row {
      justify-content: center;
    }
  }
}

pre {
  user-select: text;
}

.collection-box {
  position: relative;

  ul.collection,
  ul.collection-back {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    width: 100%;
    gap: 1.4rem;

    > li {
      color: $primary-medium;
      border: 3px solid $primary-medium;
      display: flex;
      justify-content: center;
      align-items: center;
      aspect-ratio: 1;
    }

    > li.next {
      pointer-events: none;
      border-style: dashed;
      opacity: 60%;
      cursor: default;
    }
  }

  ul.collection {
    > li {
      background-color: transparent;
      cursor: pointer;
      position: relative;
      transition: all 0.3s;

      h3 {
        font-size: 4rem;
      }

      &:hover {
        background-color: $primary-medium;
        color: $shade-light;
      }

      // Favorite star
      > img {
        position: absolute;
        top: -1.1rem;
        left: -1.2rem;
        width: 2.8rem;
        cursor: pointer;
        z-index: 99;
      }
    }
  }

  ul.collection-back {
    pointer-events: none;
    position: absolute;

    top: 5px;
    left: 5px;
    > li {
      background-color: $primary-medium;
      opacity: 0.2;
    }
  }
}

.date-badge {
  position: absolute;
  color: white;
  background: $primary-medium;
  padding: 0.4rem 0.6rem;
  font-size: 1.2rem;
  right: -1.1rem;
  bottom: -1.1rem;
  // border-radius: 99px;

  // If has the class .with-image, then show a bullet point before of the date-badge
  &.with-image::before {
    content: '❖';
    margin-right: 0.4rem;
  }
}

// Message popup
.message {
  position: relative;
  background-color: $shade-light;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  p {
    line-height: 1.6;
    letter-spacing: 0.5px;
  }
}

button.btn-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  border: none;
  cursor: pointer;
  font-size: 2rem;
  color: $shade-light;
  background-color: $primary-medium;
  width: 4rem;
  aspect-ratio: 1;
}

button.btn-favorite {
  background: none;
  display: inline-block;
  width: max-content;
  border: none;
  cursor: pointer;
  padding: 0.4rem;
  border-radius: 5px;
  font-family: 'Outfit', sans-serif;
  color: $primary-light;
  text-transform: uppercase;
  letter-spacing: 2px;

  display: flex;
  align-items: center;

  border: 2px solid $primary-light;

  > img {
    width: 2.8rem;
  }

  &.active {
    border: 2px solid $primary-light;
    background-color: $primary-light;
  }
}
