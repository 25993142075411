@import 'colors';
@import 'fonts';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;

  &:focus {
    outline: none;
  }
}

html {
  /* Percentage of user's browser font-size setting */
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  font-family: 'Outfit', sans-serif;
  font-size: 1.6rem;
  letter-spacing: 0.3px;
  color: $primary-medium;
  background-image: url('../texture.png');
}

em {
  font-style: inherit;
  color: $primary-medium;
}

.muted {
  opacity: 0.65;
}

body {
  background-color: $primary-dark;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4rem;
}

.icon-inline {
  width: 2.8rem;
}

.main {
  opacity: 1;
  transition: all 0.4s;

  &.hidden {
    opacity: 0;
  }

  min-height: 100vh;
  padding: 2rem 0;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;

  .tagline {
    padding: 1rem 1.4rem;
    background-color: $translucent;
    border-radius: 5px;
    color: $shade-light;
    font-size: 1.4rem;

    // Overwritten on mobile
    // position: fixed;
    // bottom: 0;
    // z-index: -1;
  }

  .container {
    padding: 3rem;
    background-color: $shade-light;
    border-radius: 5px;
    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.2);
  }

  .countdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;

    header {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.4rem;
      width: 100%;

      img {
        width: 12rem;
        margin: 2rem;
        opacity: 0.8;
      }

      > p {
        font-family: 'Satisfy', cursive;
        font-size: 2.4rem;
        opacity: 0.6;
      }
    }

    ul.countdown-group {
      display: flex;
      // flex-wrap: wrap;
      gap: 1rem;

      li.countdown-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .countdown-item-number {
          border-radius: 5px;
          font-size: 2rem;
          padding: 0.4rem 0.8rem;
          text-align: center;
          width: 8rem;
          font-weight: bold;
          color: $primary-medium;
          margin-bottom: 0.4rem;
          border: 1px solid $primary-light;
        }

        .countdown-item-label {
          color: $primary-light;
          letter-spacing: 1px;
          text-transform: uppercase;
          font-size: 1.2rem;
        }
      }
    }

    span.celebrate {
      text-align: center;
    }
  }
}

.loading-message {
  font-size: 1.6rem;
  display: flex;
  width: 100%;
  height: 100vh;
  position: absolute;
  justify-content: center;
  align-items: center;

  > span {
    padding: 2rem 4rem;
    color: $shade-light;
    background-color: $translucent;
    border-radius: 5px;
  }
}

.logo {
  transition: transform 0.4s;
  &:hover {
    transform: scale(1.2);
  }
}

.time-zones {
  display: flex;
  justify-content: center;
  gap: 1.2rem;
  > .time-zone {
    width: 16rem;
    color: $primary-light;
    text-align: center;
    font-weight: bold;

    i {
      margin-right: 0.4rem;
    }
  }
}

.activity {
  font-weight: normal;
  font-size: 1.4rem;
  margin-top: 0.8rem;
}
