@import 'colors';

@media (max-width: 600px) {
  .main {
    justify-content: flex-start;
    height: auto;

    .container {
      width: 100%;
      border-radius: 0;

      ul.countdown-group {
        width: 100%;
        flex-direction: column;
        gap: 0rem;

        li.countdown-item {
          width: 100%;
          flex-direction: row;
          border-radius: 5px;
          padding: 1rem;
          gap: 1rem;

          .countdown-item-number {
            background-color: none;
            margin-bottom: 0;
            width: auto;
          }

          .countdown-item-label {
            color: $shade-medium;
          }
        }
      }
    }

    .tagline {
      position: inherit;
    }
  }
}
