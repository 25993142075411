.popup.madlibs {
  background-color: #d9d9d9;

  * {
    color: #444444;
  }

  button.btn-close {
    background-color: #d9d9d9;

    > i {
      color: #444444;
    }
  }

  p {
    font-family: Georgia, 'Times New Roman', Times, serif;
  }
}
